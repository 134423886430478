.skills__container {
    grid-template-columns: repeat(2, 350px);
    column-gap: 3rem;
    justify-content: center;
}

.skills__content {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2rem 4rem;
    border-radius: 1.25rem;
    cursor: pointer;
}

.skills__content:hover {
    transform: translate(00.25rem, -00.25rem);
    box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.05);
    transition: 0.3s;
}

.skills__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    text-align: center;
    margin-bottom: var(--mb-1-5);
}

.skills__button {
    color: var(--title-color);
    font-size: var(--small-font-size);
    display: inline-flex;
    align-items: center;
    column-gap: 0.24rem;
    cursor: pointer;
}

.skills__button-icon {
    font-size: 1rem;
    transition: 0.3s;
}

.skills__content:hover .skills__button-icon {
    transform: translateX(00.25rem);
}

.skills__box {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.skills__box-footer {
    display: flex;
    justify-content: center;
    column-gap: 2.5rem;
}

.skills_group {
    display: grid;
    align-items: flex-start;
    row-gap: 1rem;
}

.skills__data {
    display: flex;
    column-gap: 0.5rem;
}

.skills .bx-badge-check {
    font-size: 1rem;
    color: var(--title-color);
}

.skills__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    line-height: 18px;
}

.skills__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
}

.skills__modal-content {
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
}

.skills__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
}

.skills__modal-title,
.skills__modal-description {
    text-align: center;
}

.skills__modal-title {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1);
}

.skills__modal-description {
    font-size: var(--small-font-size);
    padding: 0 3.5rem;
    margin-bottom: var(--mb-2);
}

.skills__modal-services {
    row-gap: 0.75rem;
}

.skills__modal-service {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.skills__modal-icon {
    color: var(--title-color);
    font-size: 1.1rem;
}

.skills__modal-info {
    font-size: var(--small-font-size);
}

.active-modal {
    opacity: 1;
    visibility: visible;
}

/* For large devices */
@media screen and (max-width: 992px) {
    .skills__container {
        grid-template-columns: max-content;
        row-gap: 2rem;
    }
}

@media screen and (max-width: 576px) {
    .skills__container {
        grid-template-columns: 1fr;
    }

    .skills__content {
        padding: 1.5rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .skills__box {
        column-gap: 1.25rem;
    }

    .skills__name {
        font-size: var(--small-font-size);
    }
}
