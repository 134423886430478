.dropdown-content {
    position: absolute;
    background-color: var(--container-color);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.dropdown-content a {
    color: var(--text-color);
    padding: 12px 16px;
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
    display: block;
}

.dropdown-content a:hover {
    background-color: var(--body-color);
}
