.project-container {
    display: flex;
    min-height: 100vh;
    margin: 0 auto;
    padding-top: 5rem;
    line-height: 1.6;
}

.project__img {
    max-width: 50%;
    max-height: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 50px;
}

.project-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    align-items: center;
}

.project-content-details {
    max-width: 800px;
}

.project-header {
    text-align: center;
    margin-bottom: 30px;
    scroll-snap-margin-top: 200px;
}

.project-section {
    margin-bottom: 40px;
    scroll-snap-margin-top: 80px;
}

.project-section h2 {
    text-align: center;
}

.project-section p {
    line-height: 30px;
}

.project-section ol {
    margin-left: 20px;
}

.project-section h2 {
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
