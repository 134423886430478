.sidebar-container {
    position: '-webkit-sticky';
    top: 0;
    left: 0;
    width: 200px;
    padding: 10px;
    background-color: #f7f7f7;
    border-right: 1px solid #ddd;
}

.sidebar {
    position: fixed;
    width: 200px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    margin: 10px 0;
}

.sidebar a {
    text-decoration: none;
    color: #333;
}

.sidebar .active a,
a:hover {
    font-weight: bold;
    color: #007bff;
}
